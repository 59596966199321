(function($) {
    if (typeof Drupal !== "undefined") {
        Drupal.behaviors.customThemeFile = {
            attach: function(context, settings) {
                init();
            }
        };
    } else {
        init();
    }

    function init() {


        // const formConsult = document.querySelector(".webform-submission-get-consult-form");
        // if (formConsult) {
        //
        //     let phone = formConsult.querySelector('[id^="edit-flexbox-email"]');
        //     let email = formConsult.querySelector('[id^="edit-flexbox"]');
        //
        //     email.insertAdjacentHTML('beforeend', `<div class="blocked-field"></div>`);
        //
        //     formConsult.querySelectorAll('[type="radio"]').forEach(item => {
        //
        //         if (!formConsult.querySelector('[id^="edit-flexbox"] .blocked-field')) {
        //             formConsult.querySelector('[id^="edit-flexbox"]').insertAdjacentHTML('beforeend', `<div class="blocked-field"></div>`);
        //             formConsult.querySelector('[id^="edit-flexbox"]').classList.add('is-disabled');
        //         }
        //         if (!formConsult.querySelector('[id^="edit-flexbox-email"] .blocked-field')) {
        //             formConsult.querySelector('[id^="edit-flexbox-email"]').insertAdjacentHTML('beforeend', `<div class="blocked-field"></div>`);
        //             formConsult.querySelector('[id^="edit-flexbox-email"]').classList.add('is-disabled');
        //         }
        //
        //         item.addEventListener('click', e => {
        //
        //             // email
        //             if (e.currentTarget.getAttribute('id') === 'rd1') {
        //                 formConsult.querySelector('[id^="edit-flexbox-email"] .blocked-field').remove();
        //                 formConsult.querySelector('[id^="edit-flexbox-email"]').classList.remove('is-disabled');
        //                 if (!formConsult.querySelector('[id^="edit-flexbox"] .blocked-field')) {
        //                     formConsult.querySelector('[id^="edit-flexbox"]').classList.add('is-disabled');
        //                     formConsult.querySelector('[id^="edit-flexbox"]').insertAdjacentHTML('beforeend', `<div class="blocked-field"></div>`);
        //                 }
        //             }
        //
        //             // phone
        //             if (e.currentTarget.getAttribute('id') === 'rd2') {
        //                 formConsult.querySelector('#edit-flexbox .blocked-field').remove();
        //                 formConsult.querySelector('[id^="edit-flexbox"]').classList.remove('is-disabled');
        //                 if (!formConsult.querySelector('[id^="edit-flexbox-email"] .blocked-field')) {
        //                     formConsult.querySelector('[id^="edit-flexbox-email"]').classList.add('is-disabled');
        //                     formConsult.querySelector('[id^="edit-flexbox-email"]').insertAdjacentHTML('beforeend', `<div class="blocked-field"></div>`);
        //                 }
        //             }
        //
        //             // formConsult.querySelectorAll('[type="radio"]').forEach(item2 => {
        //             //
        //             //     formConsult.querySelector('#edit-flexbox-phone .blocked-field').remove();
        //             //     formConsult.querySelector('#edit-flexbox-email .blocked-field').remove();
        //             //
        //             //     item2.querySelector('.blocked-field').remove();
        //             //
        //             // });
        //         });
        //
        //     });
        //
        //     // при открытии страницы
        //
        //
        // }
        //
        //
    }

    $(document).ready(function(){
        $("table").wrap("<div class=\"table-wrapper\"></div>");

        if ( $(".main-content__images").length ) {
            lightGallery(".main-content__images", "a");
        }


        jQuery.validator.setDefaults({
            debug: true,
            errorElement: "div",
            // errorClass: "form-item--error-message",
            rules: {
                '[name="email"]': {
                    email: true,
                    pattern: /^[a-zA-Z0-9_-]+\.*@[a-zA-Z0-9-]+\.[a-z]{2,6}$/i,
                },
                '[name="phone"]': {
                    required: true,
                    // pattern: /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/i,
                    // pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                    // pattern: /^((\+7|8)[0-9]{10})$/,
                    pattern: /^((\+7|8)[0-9]{10})$/,
                    // pattern: /^((\+7|8)[\- ]?[\(]?[0-9]{3,4}[\)]?[\- ]?[0-9]{2,3}[\- ]?[0-9]{2}[\- ]?[0-9]{2})$/,
                },
            },
            messages: {
                '[name="email"]': {
                    email: "Пожалуйста, введите корректный адрес электронной почты",
                    pattern: "Пожалуйста, введите корректный адрес электронной почты",
                },
                '[name="phone"]': {
                    pattern: "Неверный номер",
                },
            },
        });

        $("form.webform-submission-form").each(function () {
            var form = $(this);
            form.find(".form-item input").on("keypress blur", function (e) {
                form.validate().element(e.currentTarget);
            });
        });
        if (typeof Drupal !== "undefined" && typeof Drupal.Ajax !== "undefined" && typeof Drupal.Ajax.prototype.beforeSubmitCVOriginal === "undefined") {
            Drupal.Ajax.prototype.beforeSubmitCVOriginal = Drupal.Ajax.prototype.beforeSubmit;
            Drupal.Ajax.prototype.beforeSubmit = function (form_values, element_settings, options) {
                if (options.data._triggering_element_name === "op") {
                    if (typeof this.$form !== "undefined") {
                        // $(this.$form).validate(drupalSettings.cvJqueryValidateOptions);
                        if (this.$form.valid()) {
                            // console.log("ФОРМА ВАЛИДНА");
                        } else {
                            // console.log("ФОРМА НЕ ВАЛИДНА");
                            this.ajaxing = false;
                            return false;
                        }
                    }
                }
                return this.beforeSubmitCVOriginal(form_values, element_settings, options);
            };
        }




    });

})(jQuery);
